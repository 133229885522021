import React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <div
      className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5 justify-content-between">
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Address</h4>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>Car Trade Centre,
              Biratnagar, Morang, Koshi Province, Nepal
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>+977 9851319271
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>contact@ctcnepal.com
            </p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-youtube"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Opening Hours</h4>
                    <h6 className="text-light">Monday - Friday:</h6>
                    <p className="mb-4">09.00 AM - 09.00 PM</p>
                    <h6 className="text-light">Saturday - Sunday:</h6>
                    <p className="mb-0">09.00 AM - 12.00 PM</p>
                </div> */}
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Services</h4>
            <a className="btn btn-link" onClick={() => navigate("/buy")}>
              Buy Car
            </a>
            <a className="btn btn-link" onClick={() => navigate("/sell")}>
              Sell Car
            </a>
            {/* <a className="btn btn-link" href="">Tires Replacement</a>
                    <a className="btn btn-link" href="">Oil Changing</a>
                    <a className="btn btn-link" href="">Vacuam Cleaning</a> */}
          </div>
          {/* <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Newsletter</h4>
                    <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                    <div className="position-relative mx-auto" style={{maxWidth: "400px"}}>
                        <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email"/>
                        <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                    </div>
                </div> */}
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <a className="border-bottom" href="https://www.ctcnepal.com/">
                Car Trade Centre
              </a>
              , All Right Reserved. Developed By{" "}
              <a
                className="border-bottom"
                href="https://www.siancesoftware.com/"
              >
                Siance Software Pvt. Ltd.
              </a>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <a onClick={() => navigate("/home")}>Home</a>
                <a onClick={() => navigate("/help")}>Help</a>
                <a onClick={() => navigate("/privacy")}>Privacy</a>
                <a onClick={() => navigate("/terms")}>Terms</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
