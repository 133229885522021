import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Api from '../Utills/Api';
import { FaSearch } from "react-icons/fa";

const Search = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Debounced search function
  const debouncedSearch = useCallback(
    _.debounce(async (searchQuery) => {
      if (searchQuery.trim() === '') {
        setResults([]);
        return;
      }
      setLoading(true);
      setError(null);
      try {
        console.log('Searching for:', searchQuery);
        const response = await Api.post('car/search_car', { name: searchQuery });
        console.log('Response data:', response.data);
        if (response.data && response.data.car_details) {
          setResults(response.data.car_details);
        } else {
          setResults([]);
        }
      } catch (err) {
        console.error('Search error:', err);
        setError('An error occurred while searching. Please try again.');
      } finally {
        setLoading(false);
      }
    }, 300), // Delay in milliseconds
    []
  );

  const handleSearch = (text) => {
    console.log('Handling search with:', text);
    setQuery(text);
    debouncedSearch(text);
  };

  return (
    <div style={styles.container}>
      <div style={{position:"relative"}}>
      <input
        style={styles.input}
        placeholder="Search..."
        value={query}
        onChange={(e) => handleSearch(e.target.value)}
      />
      <FaSearch style={{position:"absolute",top:"16px",left: "12px"}}/>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p style={styles.error}>{error}</p>}
      <ul style={styles.list}>
        {results.map(item => (
          <li
            key={item.id}
            style={styles.listItem}
            onClick={() => 
            navigate('/result', { state: { id : item.id, location_id:item.location_id} })}
          >
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end', // Centers the children elements horizontally
  },
  input: {
    backgroundColor: "rgb(251 247 247)",
    padding: "13px 37px",
    borderRadius: '8px',
    width: '300px',
    // margin: '0 auto',
    display: 'block',
    outline:"none",
    boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
    border: 'none',
  },
  error: {
    color: 'red',
  },
  list: {
    backgroundColor: '#f2f2f2',
    marginTop: '5px',
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Centers the list items horizontally
    borderRadius: '10px',
    padding: 0,
    listStyleType: 'none',
  },
  listItem: {
    width: '100%', // Make sure the list items take full width for better alignment
    padding: '10px',
    margin: '5px 0',
    cursor: 'pointer',
    color: 'black',
    fontWeight: '600',
    fontSize: '15px',
    textAlign: 'center',
  },
};

export default Search;
