import React from 'react'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Footer'

function Contacts() {
  return (
    <div>
        <NavBar/>
        <section class="py-5 overflow-hidden" id="home">
   
<div class="container mt-8" id="dish_list">
    <div class="row h-100">
        <div class="col-lg-7 mx-auto text-center mb-6">
            <h5 class="fw-bold fs-3 fs-lg-5 lh-sm mb-3">Help Centre</h5>
        </div>
    </div>
            <h3>Car Trade Centre</h3>
            <h3>Buy and Sell second hand cars</h3>
            <h3>Address</h3>
            <h3>Car Trade Centre, Biratnagar, Morang, Koshi Province, Nepal</h3>
            <h3>Phone number - +977 9851319271</h3>
            <h3>Email - contact@ctcnepal.com</h3>
        </div>
  
</section>
        <Footer/>
    </div>
  )
}

export default Contacts