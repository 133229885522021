import React, { useState, useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import Api from "../Utills/Api";
import Search from "../Buying/Search";
import { FaFilter } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { ImCross } from "react-icons/im";

function Buy() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 717);
  const [isSmallScreen1, setIsSmallScreen1] = useState(
    window.innerWidth <= 602
  );
  const userId = localStorage.getItem("user_id");
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [visibleCount, setVisibleCount] = useState(12);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedColor, setSelectedColor] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [colorId, setColorId] = useState("");
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  const Min = [0, 1000, 10000, 100000, 1000000, 10000000, 100000000];
  const Max = [
    0, 1000, 10000, 100000, 1000000, 10000000, 100000000, 1000000000,
  ];
  const [isHovered, setIsHovered] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedMin, setSelectedMin] = useState("0");
  const [selectedMax, setSelectedMax] = useState("10000000");

  const handleSubmit = async () => {
    try {
      console.log("brand_id", selectedBrands);
      console.log("minValue", selectedMin);
      console.log("maxValue", selectedMax);
      const response = await Api.get(
        `car/car_filter?brand_id=${selectedValue}&min_sale_price=${selectedMin}&max_sale_price=${selectedMax}&color_id=${colorId}`
      );
      setProducts(response.data.get_car_list);
      setSelectedOption("");
      console.log("API Response:", response);
    } catch (error) {
      console.log("Error submitting data:", error);
    }
  };

  const handleClear = () => {
    setSelectedMin("0");
    setSelectedMax("100000000");
    setSelectedValue(" ");
    setColorId(" ");
  };

  const toggleSlideView = () => {
    setIsOpen(!isOpen);
  };

  const headerStyle = {
    backgroundImage: "url(img/carousel-bg-2.jpg)",
  };

  //   setSelectedBrands((prev) =>
  //     prev.includes(id)
  //       ? prev.filter((brandId) => brandId !== id)
  //       : [...prev, id]
  //   );
  // };

  // const filteredBrands = brands.filter((brand) =>
  //   brand.title.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // const handleCheckboxChangeColor = (id) => {
  //   setSelectedBrands((prev) =>
  //     prev.includes(id)
  //       ? prev.filter((colorId) => colorId !== id)
  //       : [...prev, id]
  //   );
  // };

  // const filteredColors = colors.filter((color) =>
  //   color.color_name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const getProducts = async () => {
    try {
      const response = await Api.get(`car_list`);
      const response1 = await Api.get(`brand`);
      const response2 = await Api.get(`color`);
      setProducts(response.data.get_car_list);
      setBrands(response1.data.get_brand_details);
      setColors(response2.data.color);
    } catch (error) {
      console.log("Error fetching products:", error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 717);
      setIsSmallScreen1(window.innerWidth <= 602);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getDropdownData = async () => {
    try {
      const response = await Api.get(`car/car_sort?sort=${selectedOption}`);
      setProducts(response.data.get_car_list);
      setIsOpen(false);
    } catch (error) {
      console.log("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      getDropdownData();
    }
  }, [selectedOption]);

  const ownerButton = async (event, user_id, sale_id) => {
    event.preventDefault();
    if (userId) {
      try {
        await Api.post(`car/add_wishlist`, {
          user_id: userId,
          sale_id: sale_id,
        });
        navigate("/seller", { state: { user_id: user_id } });
      } catch (error) {
        console.log("Error adding to wishlist:", error);
      }
    } else {
      navigate("/login");
    }
  };

  const productsToShow = products.slice(0, visibleCount);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const styles1 = {
    clearBtn: {
      background: isHovered ? "rgb(1, 53, 84)" : "none",
      border: "none",
      color: isHovered ? "white" : "black",
      cursor: "pointer",
      padding: "5px 10px",
      transition: "background-color 0.3s ease, color 0.3s ease",
      borderRadius: 7,
      border: isHovered ? "" : "1px solid black",
      marginTop:"20px",
    },
  };

  return (
    <div>
      <NavBar />

      <div className="container-fluid page-header mb-5 p-0" style={headerStyle}>
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Buy Car
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <a onClick={() => navigate("/")} style={{ color: "orange" }}>
                    Home
                  </a>
                </li>
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Buy Cars
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: isSmallScreen ? "19px" : "",
            alignItems: isSmallScreen ? "center" : "flex-start",
            justifyContent: "space-around",
            flexDirection: isSmallScreen ? "column" : "row",
          }}
        >
          <button style={styles.filter_btn} onClick={toggleSlideView}>
            <FaFilter />
            Filter
          </button>
          <div
            className={`slideView ${isOpen ? "open" : ""}`}
            style={{
              ...styles.slideView,
              display: isOpen ? "block" : "none",
              marginTop: isSmallScreen ? 1019 : 119,
            }}
          >
            <div
              style={{
                borderBottom: "1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "15px",
              }}
            >
              <h5 style={{ fontSize: "28px" }}>Filter</h5>
              <button onClick={toggleSlideView} style={styles.closeBtn}>
                <ImCross />
              </button>
            </div>

            <button
              onClick={handleClear}
              style={styles1.clearBtn}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              CLEAR
            </button>
            <div
              style={{
                borderBottom: "1px solid #a9a9a9",
                paddingBottom: "23px",
              }}
            >
              <h5 style={{ fontSize: "22px", marginTop: "13px" }}>Brands</h5>
              <select
                style={{
                  width: "100%",
                  padding: "10px",
                  marginBottom: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                <option value="">Select Car Brand</option>
                {brands.map((brand) => (
                  <option key={brand.id} value={brand.id}>
                    {brand.title}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                borderBottom: "1px solid #a9a9a9",
                paddingBottom: "23px",
              }}
            >
              <h5 style={{ fontSize: "22px", marginTop: "13px" }}>Colors</h5>
              <select
                style={{
                  width: "100%",
                  padding: "10px",
                  marginBottom: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                onChange={(e) => setColorId(e.target.value)}
              >
                <option value="">Select Car Color</option>
                {colors.map((color) => (
                  <option key={color.id} value={color.id}>
                    {color.color_name}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ padding: "20px", borderBottom: "1px solid #a9a9a9" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: "15px",
                }}
              >
                <h5 style={{ fontSize: "22px", marginTop: "13px" }}>Price</h5>
              </div>
              <div className="slider-values">
                <select
                  style={{
                    width: "42%",
                    padding: "5px 6px",
                    border: "none",
                    outline: "none",
                    borderRadius: "7px",
                  }}
                  value={selectedMin}
                  onChange={(e) => setSelectedMin(e.target.value)}
                >
                  {Min.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <span> to </span>
                <select
                  style={{
                    width: "42%",
                    padding: "5px 6px",
                    border: "none",
                    outline: "none",
                    borderRadius: "7px",
                  }}
                  value={selectedMax}
                  onChange={(e) => setSelectedMax(e.target.value)}
                >
                  {Max.filter((value) => value > selectedMin).map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button style={styles.btn} onClick={handleSubmit}>
              Submit
            </button>
          </div>

          <div style={styles.container}>
            <div style={{ position: "relative" }}>
              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleSelectChange}
                style={styles.select}
              >
                <option value="" disabled>
                  Sort
                </option>
                <option value="NewestFirst">Newest First</option>
                <option value="lowToHigh">Price: Low to High</option>
                <option value="highToLow">Price: High to Low</option>
              </select>
            </div>
          </div>
          <Search />
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div
            className={`row ${isOpen ? "g-3" : "g-4"}`}
            style={{
              marginLeft: !isOpen ? "" : !isSmallScreen1 ? "252px" : "",
            }}
          >
            {productsToShow.map((member) => (
              <div
                // className="col-sm-6 col-lg-3 col-md-6 wow fadeInUp"
                className={`${
                  isOpen
                    ? "col-sm-12 col-lg-4 col-md-6 wow fadeInUp"
                    : "col-sm-6 col-lg-3 col-md-6 wow fadeInUp"
                }`}
                data-wow-delay={member.delay}
                key={member.id}
                style={{
                  height: "300px",
                }}
              >
                <div
                  className="team-item team-item1"
                  style={{
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                    borderRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div
                    className="all-car position-relative"
                    style={{ height: "60%" }}
                  >
                    <img
                      className="buy-car-image"
                      src={`https://admin.ctcnepal.com/uploads/car_banner/${member.car_banner}`}
                      alt=""
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        height: "100%",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        transform: "transition 0.5s",
                      }}
                    />
                    <div className="rotateImage team-overlay">
                      <a
                        className="btn btn-square mx-1"
                        href=""
                        style={{
                          width: "70px",
                          height: "70px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transform: "transition 0.5s",
                          borderRadius: "7px",
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          navigate(`/productDetails?sale_id=${member.id}`, {
                            state: { sale_id: member.id },
                          });
                        }}
                      >
                        <p style={{ margin: 0 }}>Buy Now</p>
                      </a>
                      <a
                        className="btn btn-square mx-1"
                        href=""
                        style={{
                          width: "70px",
                          height: "70px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "7px",
                        }}
                        onClick={(event) =>
                          ownerButton(event, member.user_id, member.id)
                        }
                      >
                        <p style={{ margin: 0 }}>Seller Details</p>
                      </a>
                    </div>
                  </div>
                  <div
                    className="bg-light text-center"
                    style={{
                      padding: "13px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                      height: "40%",
                    }}
                  >
                    <h5
                      className="fw-bold"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "90%",
                        margin: "0 auto",
                      }}
                    >
                      {member.title} {member.model}
                    </h5>
                    <h5 className="fw-normal">रु.{member.sale_price}</h5>
                    <p
                      style={{
                        fontSize: ".7em",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "90%",
                        margin: "0 auto",
                      }}
                    >
                      {member.km_running}km • {member.color} •{" "}
                      {member.fuel_type}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {visibleCount < products.length && (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary"
                onClick={() => setVisibleCount(visibleCount + 12)}
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

const styles = {
  container: {
    width: "300px",
    textAlign: "center",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    outline: "none",
    // boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
    border: "none",
  },
  label: {
    fontSize: "16px",
    marginBottom: "10px",
    display: "block",
  },
  select: {
    backgroundColor: "rgb(247 246 246)",
    padding: "13px 32px",
    fontSize: "16px",
    width: "300px",
    borderRadius: "8px",
    border: "none",
    outline: "none",
    boxShadow:
      "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
  },
  filter_btn: {
    backgroundColor: "rgb(247 246 246)",
    padding: "11px 27px",
    fontSize: "16px",
    borderRadius: "8px",
    border: "none",
    outline: "none",
    boxShadow:
      "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  slideView: {
    position: "absolute",
    left: 0,
    // right: "-100%",
    display: "none",
    width: 300,
    backgroundColor: "#f1f1f1",
    boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.5)",
    transition: "right 0.3s ease",
    padding: 20,
    zIndex: 100,
  },
  closeBtn: {
    color: "red",
    border: "none",
    padding: "10px",
    cursor: "pointer",
  },
  open: {
    // right: 0,
  },
  brandList: {
    maxHeight: "300px",
    overflowY: "scroll",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    backgroundColor: "#fff",
  },
  brandItem: {
    marginBottom: "10px",
    display: "flex",
    gap: "11px",
  },
  horizontalSlider: {
    margin: "10px 0",
    width: "100%",
    height: "8px",
    backgroundColor: "#ddd",
    borderRadius: "4px",
  },
  thumb: {
    height: "20px",
    width: "20px",
    backgroundColor: "#007bff",
    borderRadius: "50%",
    cursor: "grab",
  },
  track: {
    backgroundColor: "#007bff",
    height: "8px",
    borderRadius: "4px",
  },
  priceHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  clearBtn: {
    background: "none",
    border: "none",
    color: "#007bff",
    cursor: "pointer",
  },
  sliderValues: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  searchInput: {
    padding: "7px 11px",
    width: "259px",
    border: "none",
    outline: "none",
    marginBottom: "10px",
    borderRadius: "8px",
  },
  btn: {
    width: "100%",
    backgroundColor: "#013554",
    color: "white",
    padding: "8px 0px",
    marginTop: "20px",
    borderRadius: "12px",
  },
};

export default Buy;
