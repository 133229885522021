import React, { useState, useEffect } from "react";
import NavBar from "../Components/NavBar";
import { Carousel } from "react-responsive-carousel";
import Select from "react-select";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Api from "../Utills/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";

const repairOptions = [
  { value: "00k - 25k", label: "00k - 25k" },
  { value: "25k-50k", label: "25k-50k" },
  { value: "50k-75k", label: "50k-75k" },
  { value: "75k-1L", label: "75k-1L" },
  { value: ">1L", label: ">1L" },
];
const fuel = [
  { label: "Petrol", value: "Petrol" },
  { label: "Diesel", value: "Diesel" },
  { label: "Electric", value: "Electric" },
  { label: "Hybrid", value: "Hybrid" },
];
const tyre = [
  { label: "25% used", value: "25% used" },
  { label: "50% used", value: "50% used" },
  { label: "75% used", value: "75% used" },
  { label: "100% used", value: "100% used" },
];
const auction = [
  { label: "No", value: "No" },
  { label: "Private", value: "Private" },
  { label: "Government", value: "Government" },
];
const accident = [
  { label: "Minor", value: "Minor" },
  { label: "Medium", value: "Medium" },
  { label: "Big", value: "Big" },
  { label: "None", value: "None" },
];
function EditValuation() {
  const location = useLocation();
  const { valueId } = location.state || {};

  const [rowId, setRowId] = useState("");
  const [carModel, setCarModel] = useState("");
  const [cc, setCC] = useState("");
  const [tyreCondition, setTyreCondition] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [registrationZone, setRegistrationZone] = useState([]);
  const [purchaseYear, setPurchaseYear] = useState("");
  const [kmDriven, setKmDriven] = useState("");
  const [minSellingPrice, setMinSellingPrice] = useState("");
  const [maxSellingPrice, setMaxSellingPrice] = useState("");
  const [carColor, setCarColor] = useState([]);
  const [colorId, setColorId] = useState("");
  const [brands, setBrands] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedRepair, setSelectedRepair] = useState(null);
  const [selectedFuel, setSelectedFuel] = useState(null);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [selectedAccident, setSelectedAccident] = useState(null);
  const [selectedTrans, setSelectedTrans] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [planRuppes, setPlanRuppes] = useState("");

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      const response = await Api.get(
        `profile/edit_valuation_history?valuation_id=${valueId}`
      );

      const detailData = response.data.get_car_array[0];

      setRowId(detailData.id);
      setCarModel(detailData.model);
      setSelectedValue(detailData.company);
      setCC(detailData.car_cc);
      setSelectedTrans(detailData.trans_type);
      setColorId(detailData.color_id);
      setSelectedLocation(detailData.location_id);
      setPurchaseYear(detailData.purchase_year);
      setKmDriven(detailData.km_running);
      const repairOption = repairOptions.find(
        (opt) => opt.value === detailData.estimated_cost_repair
      );
      setSelectedRepair(repairOption);
      const fuelOption = fuel.find((opt) => opt.value === detailData.fuel_type);
      setSelectedFuel(fuelOption);
      const auctionOption = auction.find(
        (opt) => opt.value === detailData.auction
      );
      setSelectedAuction(auctionOption);
      const accidentOption = accident.find(
        (opt) => opt.value === detailData.accident
      );
      setSelectedAccident(accidentOption);

      setTyreCondition(detailData.tyre_condition);
      setMaxSellingPrice(JSON.stringify(detailData.expected_price_maximum));
      setMinSellingPrice(JSON.stringify(detailData.expected_price_minimum));

      const addPhotoUrls = detailData.car_image.map((img) => ({
        uri: `https://admin.ctcnepal.com/uploads/valuation_multi_image/${img.car_multi_image}`,
        image_id: img.id,
      }));


      setUploadedFiles(addPhotoUrls);
      console.log("Uploaded files : ", addPhotoUrls);

    } catch (error) {
      console.log("Error fetching details car:", error);
    }
  };


  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files); 
    const newImages = files.map((file) => ({
      uri: URL.createObjectURL(file), 
      image_id: null, 
      file 
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...newImages]);
  };

  // const handleImageUpload = (event) => {
  //   const files = Array.from(event.target.files);
  //   const imageUrls = files.map((file) => ({
  //     uri: URL.createObjectURL(file),
  //     image_id: null,
  //     file
  //   }));
  //   setUploadedFiles((prevFiles) => [...prevFiles, ...imageUrls]);
  // };

  const handleRepairChange = (selectedOption) => {
    setSelectedRepair(selectedOption);
  };
  const handleFuel = (option) => {
    setSelectedFuel(option);
  };
  const handleTyre = (option) => {
    setTyreCondition(option.value);
  };
  const handleAuction = (value) => {
    setSelectedAuction(value);
  };
  const handleAccident = (option) => {
    setSelectedAccident(option);
  };
  const handleTrans = (option) => {
    setSelectedTrans(option);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getBrands = async () => {
    try {
      const response = await Api.get(`brand`);
      const response1 = await Api.get(`valuation/get_registration_zone`);
      const response2 = await Api.get(`color`);
      const response3 = await Api.get(`valuation/get_valuation_plan`);
      setRegistrationZone(response1.data.location_data);
      setBrands(response.data.get_brand_details);
      setCarColor(response2.data.color);
      setPlanRuppes(response3.data.plan);
    } catch (error) {
      console.log("Error fetching brands:", error);
    }
  };
  useEffect(() => {
    getBrands();
  }, []);

  const handelRemove = async (image_id) => {
    try {
      const response = await Api.remove(`profile/delete_valuation_image?multi_image_id=${image_id}`);
      console.log("remove image : ", response);
      setUploadedFiles(prevFiles => prevFiles.filter(file => file.image_id !== image_id));
      getDetails()

      // ToastAndroid.show('deleted Successful', ToastAndroid.SHORT);

    } catch (error) {
      console.log(error);
    }
  };

  const styles = {
    container: {
      maxWidth: isMobile ? "100%" : "1200px",
      margin: "0 auto",
      padding: "20px",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      gap: "20px",
    },
    sliderWrapper: {
      flex: 1,
      marginBottom: "20px",
      marginTop: "25px",
    },
    productInfo: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "20px",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
    },
    productName: {
      fontSize: isMobile ? "1.5rem" : "2rem",
      marginBottom: "10px",
    },
    form: {
      width: "100%",
    },
    label: {
      fontSize: "1rem",
      marginBottom: "5px",
      display: "block",
      width: "100%",
    },
    input: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    input2: {
      width: "95%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    textarea: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    buyButton: {
      padding: "10px 20px",
      fontSize: "1rem",
      color: "#fff",
      backgroundColor: "#0073e6",
      border: "none",
      cursor: "pointer",
      alignSelf: "center",
      marginTop: "20px",
      width: isMobile ? "100%" : "auto",
    },
    uploadedImages: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      marginTop: "20px",
    },
    uploadedImage: {
      width: isMobile ? "80px" : "180px",
      height: isMobile ? "80px" : "100px",
      objectFit: "cover",
      borderRadius: "8px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    column2: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    rows: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    t2: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    optionButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "10px",
      border: "1px solid grey",
      borderRadius: "5px",
      cursor: "pointer",
    },
    selectedOption: {
      borderColor: "blue",
    },
    optionText: {
      marginLeft: "5px",
      fontSize: "16px",
    },
    confirmationModal: {
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "1000",
    },
    modalContent: {
      backgroundColor: "white",
      borderRadius: " 5px",
      textAlign: " center",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
      backgroundColor: "white",
      color: "black",
      padding: "34px 52px",
      margin: "10px",
      cursor: "pointer",
    },
    buttonBtn: {
      backgroundColor: "#0073e6",
      color: "white",
      border: "none",
      outline: "none",
      padding: "6px 19px",
    },
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxWidth: isMobile ? "100%" : "220px",
      padding: "4px",
      bottom: "5px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "95%",
    }),
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("valuation_id", rowId);
      formData.append("company", selectedValue);
      formData.append("model", carModel);
      formData.append("car_cc", cc);
      formData.append("color_id", colorId);
      formData.append("trans_type", selectedTrans);
      formData.append("purchase_year", purchaseYear);
      formData.append("location_id", selectedLocation);
      formData.append("km_running", kmDriven);
      formData.append("estimated_cost_repair", selectedRepair.value);
      formData.append("fuel_type", selectedFuel.value);
      formData.append("auction", selectedAuction.value);
      formData.append("accident", selectedAccident.value);
      formData.append("tyre_condition", tyreCondition);
      formData.append("expected_price_minimum", minSellingPrice);
      formData.append("expected_price_maximum", maxSellingPrice);

      // for (let i = 0; i < uploadedFiles.length; i++) {
      //   formData.append("car_multi_image[]", uploadedFiles[i]);
      //   console.log("url of uploaded : ",uploadedFiles[i])
      // }
      uploadedFiles.forEach((file) => {
        if (file.file) {
          formData.append("car_multi_image[]", file.file);
          console.log("Appending new file:", file.file);
        } else {
          // If it's an existing backend image, send its image_id instead
          formData.append("existing_images[]", file.image_id);
          console.log("Appending existing image ID:", file.image_id);
        }
      });
      console.log("url of uploaded : ",uploadedFiles)


      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
    }

      const response = await Api.postFormdata("profile/update_valuation", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/");

    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <NavBar />
      <div style={styles.container}>
        <div style={styles.sliderWrapper}>
          <Carousel showThumbs={false} infiniteLoop autoPlay>
            <div>
              <img
                src="https://wallpapersmug.com/download/3840x2160/37da88/mazda-off-road-5k.jpg"
                alt="Product 1"
              />
            </div>
            <div>
              <img
                src="https://wallpapersmug.com/download/3840x2160/9fe0a2/bmw-vision-m-next-concept-cars-hybrid-sports-car.jpeg"
                alt="Product 2"
              />
            </div>
            <div>
              <img
                src="https://images.wallpaperscraft.com/image/single/porsche_911_carrera_rsr_porsche_sports_car_139640_3840x2160.jpg"
                alt="Product 3"
              />
            </div>
          </Carousel>
        </div>
        <div style={styles.productInfo}>
          <h1 style={styles.productName}>Edit Valuation</h1>
          <form style={styles.form} onSubmit={handleSubmit}>
            <label style={styles.label}>Select Your Car Brand:</label>
            <select
              style={styles.input}
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
            >
              <option value="">Select Car Brand</option>
              {brands.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.title}
                </option>
              ))}
            </select>

            <label style={styles.label}>Enter Car Model/Variant:</label>
            <input
              type="text"
              style={styles.input}
              value={carModel}
              onChange={(e) => setCarModel(e.target.value)}
            />

            <div style={styles.column}>
              <label style={styles.label}>Enter Engine Power(cc):</label>
              <input
                type="text"
                style={styles.input}
                value={cc}
                onChange={(e) => setCC(e.target.value)}
              />
            </div>
            <div style={styles.row}>
              <div>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Color :
                </label>
                <select
                  style={styles.input}
                  onChange={(e) => {
                    setColorId(e.target.value);
                  }}
                  value={colorId}
                >
                  <option value="">Select color</option>
                  {carColor.map((name) => (
                    <option key={name.id} value={name.id}>
                      {name.color_name}
                    </option>
                  ))}
                </select>

              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={styles.label}>Model Year:</label>
                <input
                  type="text"
                  style={styles.input2}
                  value={purchaseYear}
                  onChange={(e) => setPurchaseYear(e.target.value)}
                />

              </div>
              <div style={styles.column}>
                <label style={styles.label}>KM Driven:</label>
                <input
                  type="text"
                  style={styles.input2}
                  value={kmDriven}
                  onChange={(e) => setKmDriven(e.target.value)}
                />

              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Estimate Repairing Cost:
                </label>
                <Select
                  value={selectedRepair}
                  onChange={handleRepairChange}
                  options={repairOptions}
                  styles={customStyles}
                />

              </div>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Registration Zone :
                </label>
                <select
                  style={styles.input}
                  value={selectedLocation}
                  onChange={(e) => {
                    setSelectedLocation(e.target.value);
                  }}
                >
                  <option value="">Select Location</option>
                  {registrationZone.map((name) => (
                    <option key={name.id} value={name.id}>
                      {name.location_name}
                    </option>
                  ))}
                </select>

              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Fuel Type:
                </label>
                <Select
                  value={selectedFuel}
                  onChange={handleFuel}
                  options={fuel}
                  styles={customStyles}
                />

              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Auction Details:
                </label>
                <Select
                  value={selectedAuction}
                  onChange={handleAuction}
                  options={auction}
                  styles={customStyles}
                />
              </div>

              <div style={styles.row}>
                <div style={styles.column}>
                  <label style={{ ...styles.label, marginBottom: "10px" }}>
                    Tyre Condition:
                  </label>
                  <Select
                    value={tyre.find((opt) => opt.value === tyreCondition)}
                    onChange={handleTyre}
                    options={tyre}
                    styles={customStyles}
                  />
                </div>
              </div>
            </div>

            <label style={styles.label}>
              Min Expected Price : (please don't use any special character)
            </label>
            <input
              type="number"
              style={styles.input}
              placeholder="please enter numeric value only"
              min="1"
              value={minSellingPrice}
              onChange={(e) => setMinSellingPrice(e.target.value)}
            />


            <label style={styles.label}>
              Max Expected Price : (please don't use any special character)
            </label>
            <input
              type="number"
              style={styles.input}
              placeholder="please enter numeric value only"
              min="1"
              value={maxSellingPrice}
              onChange={(e) => setMaxSellingPrice(e.target.value)}
            />


            <div style={styles.row}>
              <div style={styles.column}>
                <label style={styles.label}>Accidental Condition:</label>
                <Select
                  value={selectedAccident}
                  onChange={handleAccident}
                  options={accident}
                  styles={customStyles}
                />

              </div>
            </div>

            <div style={styles.rows}>
              <div style={styles.column}>
                <label style={styles.label}>Transmission type :</label>
                <div style={styles.rows}>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedTrans === "Manual" && styles.selectedOption),
                    }}
                    onClick={() => handleTrans("Manual")}
                  >
                    {selectedTrans === "Manual" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Manual</span>
                  </div>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedTrans === "Automatic" &&
                        styles.selectedOption),
                    }}
                    onClick={() => handleTrans("Automatic")}
                  >
                    {selectedTrans === "Automatic" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Automatic</span>
                  </div>
                </div>

              </div>
            </div>

            <div>
              <label style={{ marginTop: 10 }}>
                Upload Car Image (Multiple):
              </label>
              <input
                type="file"
                multiple
                onChange={handleImageUpload}
              />

              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {/* Display all uploaded files (from backend and newly uploaded) */}
                {uploadedFiles.map((file, index) => (
                  <div key={index} style={{ position: "relative", margin: '5px' }}>
                    <img
                      src={file.uri}
                      alt={`Uploaded ${index + 1}`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    />
                    <ImCross
                      onClick={() => handelRemove(file.image_id || file.uri)}
                      style={{ position: "absolute", top: "-2px", right: "-6px", color: "red", cursor: "pointer" }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <p style={{ display: "flex", gap: "10px" }}>
                Note<span>:</span>
              </p>
              <div style={{ marginLeft: "10px" }}>
                <p>1) The valuation will be sent to you in one hour.</p>
                <p>
                  2) Demand and market trends are the foundation of the stated
                  valuation. After a car is physically inspected, the price may
                  change.
                </p>
                <p>
                  3) If the offered valuation meets your needs, we are prepared
                  to make the purchase.
                </p>
                <p>
                  4) This valuation is valid for seven days following the
                  valuation.
                </p>
                <p>5) Terms and condition apply.</p>
              </div>
            </div>

            {loading ? (
              <div style={styles.buyButton}>Loading...</div>
            ) : (
              <button type="submit" style={styles.buyButton}>
                Save & Next
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditValuation;
