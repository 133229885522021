import React from 'react'
import NavBar from '../Components/NavBar'

function Contact() {
  return (
    <div>
        <NavBar/>
    </div>
  )
}

export default Contact