import React from 'react';
import { Link } from "react-router-dom";

const WhatsappIcon = () => {
  return (
    <div>
      <Link to="https://wa.me/9779851319271" target="_blank" rel="noopener noreferrer">
        <img 
          style={{
            position: "fixed",
            right: "40px",
            bottom: "120px",
            zIndex: "900",
          }} 
          src="img/whatsapp-873316_1280.png" 
          width="40" 
          alt="WhatsApp Icon" 
        />
      </Link>
    </div>
  );
};

export default WhatsappIcon;
