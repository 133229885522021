import axios from "axios";
import React,{useEffect} from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../Utills/Api";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "black",
  },
  background: {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: 'url("https://wallpaperaccess.com/full/840360.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    opacity: 0.5,
    zIndex: 1,
  },
  formWrapper: {
    backgroundColor: "white",
    padding: "2rem",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    width: "100%",
    maxWidth: "400px",
    zIndex: 2,
    position: "relative",
  },
  input: {
    width: "100%",
    padding: "0.8rem",
    margin: "0.5rem 0",
    border: "1px solid #ddd",
    borderRadius: "5px",
    backgroundColor: "white",
  },
  button: {
    width: "100%",
    padding: "0.8rem",
    margin: "0.5rem 0",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#013554",
    color: "white",
    fontSize: "1rem",
  },
  title: {
    marginBottom: "1rem",
  },
};

const formWrapperStyles = () => {
  const baseStyles = {
    ...styles.formWrapper,
  };

  if (window.innerWidth <= 768) {
    baseStyles.padding = "1.5rem";
    baseStyles.maxWidth = "300px";
  }

  if (window.innerWidth <= 480) {
    baseStyles.padding = "1rem";
    baseStyles.maxWidth = "90%";
  }

  return baseStyles;
};

const Signup = () => {
  const navigate = useNavigate();
  const [registrationZone, setRegistrationZone] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [fullName, setFullName] = useState('');
  const [phone_number, setPhonenumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!fullName || !phone_number || !email || !password || !confirmPassword) {
      toast.error("Enter all the fields");
    } else {
      try {
        setLoading(true);
        const response = await axios.post('https://admin.ctcnepal.com/api/signup', {
          full_name: fullName,
          phone_no: phone_number,
          email,
          password: password,
          location_id:selectedLocation
        });
        setLoading(false);
        console.log("Signup successful:", response.data);
        localStorage.setItem("user_id", JSON.stringify(response.data.user_id));
        navigate("/signupOtp", { state: { phone: phone_number } });
      } catch (error) {
        setLoading(false);
        console.error("Signup error:", error);
        toast.error("Signup failed. Please try again.");
      }
    }
  };

  const getLocation = async () => {
    try {
       
        const response1 = await Api.get(`valuation/get_registration_zone`);
        console.log("location : ",response1);
        setRegistrationZone(response1.data.location_data);
    } catch (error) {
        console.log("Error fetching brands:", error);
    }
};
useEffect(() => {
  getLocation();
}, []);

  return (
    <div style={styles.container}>
      <div style={styles.background}></div>
      <div style={formWrapperStyles()}>
        <h2 style={styles.title}>Signup</h2>
        <form onSubmit={handleSubmit}>
          <input
            style={styles.input}
            type="text"
            placeholder="Name"
            required
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <input
            style={styles.input}
            type="tel"
            placeholder="Phone Number"
            required
            value={phone_number}
            onChange={(e) => setPhonenumber(e.target.value)}
          />
          <input
            style={styles.input}
            type="email"
            placeholder="Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            style={styles.input}
            type="password"
            placeholder="Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            style={styles.input}
            type="password"
            placeholder="Confirm Password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div style={styles.column}>
            <select
              style={styles.input}
              placeholder="Location"
              onChange={(e) => {
                console.log(e.target.value)
                setSelectedLocation(e.target.value)
              }}
            >
              <option value="">Select Location</option>
              {registrationZone.map((name) => (
                <option key={name.id} value={name.id}>
                  {name.location_name}
                </option>
              ))}
            </select>
          </div>
          <button style={styles.button} type="submit">
            {loading ? "Loading..." : "Verify OTP"}
          </button>
        </form>
        <p style={{ display: "flex", justifyContent: "flex-end" }}>
          Already have an account?
          <button
            style={{
              color: "red",
              marginLeft: "5px",
              borderWidth: 0,
              backgroundColor: "transparent",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
            onClick={() => navigate("/login")}
          >
            Login
          </button>
        </p>
      </div>
    </div>
  );
};

export default Signup;
