import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../Utills/Api";

function History() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productStatus, setProductStatus] = useState({});
  const [visibleCount, setVisibleCount] = useState(12);

  useEffect(() => {
    const getProducts = async () => {
      const uid = localStorage.getItem("user_id");

      try {
        const response = await Api.get(
          `profile/get_sale_history?user_id=${uid}`
        );
        const fetchedProducts = response.data.get_car_array;
        console.log(fetchedProducts[0]);

        // Initialize productStatus
        const initialStatus = {};
        fetchedProducts.forEach((product) => {
          initialStatus[product.id] = {
            interested: product.status === 0,
            sold: product.status === 2,
          };
        });

        setProducts(fetchedProducts);
        setProductStatus(initialStatus);
      } catch (error) {
        console.log("Error fetching history:", error);
      }
    };

    getProducts();
  }, []);

  const toggleInterest = async (id) => {
    if (!productStatus[id].sold) {
      try {
        setProductStatus((prev) => ({
          ...prev,
          [id]: { ...prev[id], interested: !prev[id].interested },
        }));
        const response = await Api.post("profile/change_interest", {
          sale_id: id,
        });
        console.log("click", response.data);
      } catch (err) {
        console.log("Error updating interest:", err);
      }
    }
  };

  const toggleSold = async (id) => {
    if (productStatus[id].interested || !productStatus[id].sold) {
      try {
        setProductStatus((prev) => ({
          ...prev,
          [id]: { ...prev[id], sold: true, interested: false },
        }));
        const response = await Api.post("profile/car_sold", { sale_id: id });
        console.log("click", response.data);
      } catch (err) {
        console.log("Error updating sold status:", err);
      }
    }
  };

  const productsToShow = products.slice(0, visibleCount);

  const getLink = async (id) => {
    try {
      const response = await Api.get(`profile/update_plan_type?sale_id=${id}`);
      console.log(response.data.payment_url);
      
      navigate('/payall', {
        state: {
          url: response.data.payment_url,
        }
      });
    } catch (error) {
      console.error('Error fetching history:', error);
    }
  };

  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            {productsToShow.map((member) => (
              <div
                className="col-sm-6 col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay={member.delay}
                key={member.id}
                style={{ height: "auto" }}
                onClick={(event) => {
                  event.preventDefault();
                  navigate(`/productDetails?sale_id=${member.id}`, {
                    state: { sale_id: member.id },
                  });
                }}
              >
                <div
                  className="team-item"
                  style={{
                    // height:"100%",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    className="all-car position-relative"
                    style={{ height: "50%" }}
                    // style={{ height: "300px" }}
                  >
                    <img
                      className="img-fluid w-100 h-100"
                      src={`https://admin.ctcnepal.com/uploads/car_banner/${member.car_banner}`}
                      alt=""
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        height: "100%",
                        padding: "5px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                    <p
                      style={{
                        position: "absolute",
                        top: 10,
                        left: 0,
                        background: "#ffb833",
                        color: "black",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {member.plan_type}
                    </p>
                    <p
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        background: "rgba(0, 0, 0, 0.5)",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/editsell", {
                          state: { sale_id: member.id },
                        });
                      }}
                    >
                      Edit
                    </p>
                    {member.plan_type === "Free" ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            bottom:10,
                            right:10,
                            background: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                           getLink(member.id)
                          }}
                        >
                          <img
                            src="img/premium-service.png"
                            width="15"
                            alt="..."
                          />
                          <p style={{ margin: "0px" }}>Upgrade to Premium</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-center p-3">
                    <div style={{ height: "45%" }}>
                      <h5
                        className="fw-bold mb-0"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "90%",
                          margin: "0 auto",
                        }}
                      >
                        {member.title} {member.model}
                      </h5>
                      <h5 className="fw-bold mb-0">रु.{member.sale_price}</h5>
                      <p
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "90%",
                          margin: "0 auto",
                        }}
                      >
                        {member.km_running}km • {member.color} •{" "}
                        {member.fuel_type}
                      </p>
                      <div
                        className="mt-2 d-flex"
                        style={{ gap: "10px", flexDirection: "column" }}
                      >
                        <button
                          className={`btn btn-sm ${
                            productStatus[member.id].interested ||
                            productStatus[member.id].sold
                              ? "btn-success"
                              : "btn-outline-success"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleInterest(member.id);
                          }}
                          disabled={productStatus[member.id].sold}
                        >
                          {productStatus[member.id].interested ||
                          productStatus[member.id].sold
                            ? "Interested?"
                            : "Not Interested?"}
                        </button>
                        <button
                          className={`btn btn-sm ${
                            productStatus[member.id].sold
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleSold(member.id);
                          }}
                          disabled={productStatus[member.id].sold}
                        >
                          {productStatus[member.id].sold ? "Sold Out" : "Sold?"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {visibleCount < products.length && (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary"
                onClick={() => setVisibleCount(visibleCount + 12)}
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default History;
