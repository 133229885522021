import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Api from "../Utills/Api";

function ValuationList() {
  const navigate = useNavigate();

  const userId = localStorage.getItem("user_id");
  const [products, setProducts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(12);

  const getProducts = async () => {
    try {
      const response = await Api.get(
        `profile/get_valuation_history?user_id=${userId}`
      );
      console.log("wkdhsyuefghwyuegwyuefgwyuefgyu", response.data);
      setProducts(response.data.get_car_array);
    } catch (error) {
      console.log("Error fetching wishlist:", error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const editButton = (id) => {
    console.log("id in valuation page : ",id)
    navigate("/editValuation", { state: { valueId: id } });
  };
  const pdfButton = (pdfUrl) => {
    window.location.href = pdfUrl;
  };

  const productsToShow = products.slice(0, visibleCount);
  console.log("Product to show : ",productsToShow)

  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            {productsToShow.map((member) => (
              <div
                className="col-sm-6 col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay={member.delay}
                key={member.id}
                style={{ height: "300px" }}
              >
                <div
                  className="team-item"
                  style={{
                    height: "100%",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    className=""
                    style={{ height: "60%", position: "relative" }}
                  >
                    <img
                      className="buy-car-image"
                      src={member.car_image}
                      alt=""
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        height: "100%",
                        padding: "5px",
                        borderRadius: "10px",
                      }}
                    />
                    {member.mail_status === 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          cursor:"pointer"
                        }}
                        
        onClick={()=>editButton(member.id)}
                      >
                        <p
                          style={{
                            border: "1px solid black",
                            paddingRight: 5,
                            paddingLeft: 5,
                            borderRadius: 8,
                            backgroundColor: "#f2f2f2",
                            color: "black",
                        }}
                        >
                          Edit
                        </p>
                      </div>
                    )}
                    {member.status === 2 && (
                      <div
                        style={{
                          position: "absolute",

                          top: 10,
                          right: 10,
                        }}
        onClick={()=> pdfButton(member.pdf_url)}

                      >
                        <p
                          style={{
                            border: "1px solid red",
                            paddingRight: 5,
                            paddingLeft: 5,
                            borderRadius: 8,
                            backgroundColor: "#f2f2f2",
                            color: "red",
                          cursor:"pointer"
                          }}
                        >
                          PDF
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    className="bg-light text-center"
                    style={{
                      padding: "13px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                      height: "40%",
                    }}
                  >
                    <h5
                      className="fw-bold"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "90%",
                        margin: "0 auto",
                      }}
                    >
                      {member.title} {member.model}
                    </h5>
                    <h5 className="fw-normal">{member.valuation_code}</h5>
                    <p
                      style={{
                        fontSize: ".7em",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "90%",
                        margin: "0 auto",
                      }}
                    >
                      Created Date : {member.created_date}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {visibleCount < products.length && (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary"
                onClick={() => setVisibleCount(visibleCount + 12)}
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ValuationList;
