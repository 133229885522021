import React, { useState, useEffect } from "react";
import Api from "../Utills/Api";

const MyProfile = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [profile, setProfile] = useState({});
  const [preview, setPreview] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [registrationZone, setRegistrationZone] = useState([]);

  useEffect(() => {
    const getProfile = async () => {
      const uid = localStorage.getItem("user_id");

      try {
        const response = await Api.get(`profile?user_id=${uid}`);
        const response1 = await Api.get(`valuation/get_registration_zone`);
        setRegistrationZone(response1.data.location_data);
        const userData = response.data.get_user_data[0];
        setProfile(userData);
        setName(userData.full_name);
        setLocation(userData.location_id);
        setEmail(userData.email); // assuming email and phone are also fetched similarly
        setPhone(userData.phone_no);
      } catch (error) {
        console.log("Error fetching profile:", error);
        // Handle error here
      }
    };

    const getProfileImage = async () => {
      const uid = localStorage.getItem("user_id");

      try {
        const response = await Api.get(`profile/get_profile_pic?user_id=${uid}`);
        setProfilePic(response.data.get_profile_pic);
      } catch (error) {
        console.log("Error fetching profileImage:", error);
      }
    };

    getProfile();
    getProfileImage();

    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setPreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setProfileImage(file);

      const formData = new FormData();
      formData.append("user_id", localStorage.getItem("user_id"));
      formData.append("user_image", file);

      try {
        const response = await Api.postFormdata(`profile/update_profile_pic`, formData);
        setProfilePic(response.data.get_profile_pic);
        window.location.reload();
      } catch (error) {
        console.error("Error updating profile picture:", error);
        // Handle error here
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const uid = localStorage.getItem("user_id");

    const profileData = {
      user_id: uid,
      full_name: name,
      location_id: location,
    };

    try {
      const response = await Api.post(`update_profile`, profileData);
      setProfile(response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error here
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 50, marginBottom: 50 }}>
      <div
        className="py-5 overflow-hidden "
        style={{
          maxWidth: "800px",
          padding: "20px",
          backgroundColor: "#FFF",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          alignSelf: "center",
        }}
      >
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "20px" }}>
            <label className="d-none d-lg-inline" style={{ fontWeight: "bold" }}>
              Name:
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "4px",
                fontSize: "16px",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label className="d-none d-lg-inline" style={{ fontWeight: "bold" }}>
              Email:
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "4px",
                fontSize: "16px",
              }}
              disabled
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label className="d-none d-lg-inline" style={{ fontWeight: "bold" }}>
              Phone:
            </label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "4px",
                fontSize: "16px",
              }}
              disabled
            />
          </div>
          {/* <div style={{ marginBottom: "20px" }}>
            <label className="d-none d-lg-inline" style={{ fontWeight: "bold" }}>
              Location:
            </label>
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "4px",
                fontSize: "16px",
              }}
            />
          </div> */}
          <div style={{ marginBottom: "20px" }}>
            <label style={{ marginBottom: "10px",fontWeight: "bold"}}>Location :</label>
            <select
              value={location}
              onChange={(e) => {
                setLocation(e.target.value)
              }}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "4px",
                fontSize: "16px",
              }}
            >
              <option value="">Select Location</option>
              {registrationZone.map((name) => (
                <option key={name.id} value={name.id}>
                  {name.location_name}
                </option>
              ))}
            </select>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label className="d-none d-lg-inline" style={{ fontWeight: "bold" }}>
              Profile Picture:
            </label>
            <input type="file" accept="image/*" onChange={handleImageChange} />
          </div>
          <div style={{ margin: 10 }}>
            {preview && (
              <img
                src={preview}
                alt="Preview"
                style={{
                  width: isMobile ? "80px" : "180px",
                  height: isMobile ? "80px" : "180px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            )}
          </div>
          <button
            className="btn btn-primary py-3 px-lg-5"
            type="submit"
            style={{
              color: "#fff",
              padding: "10px 20px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              fontSize: "16px",
              transition: "background-color 0.3s ease",
            }}
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default MyProfile;
