import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import { Carousel } from "react-responsive-carousel";
import Select from "react-select";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Api from "../Utills/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const repairOptions = [
  { value: "00k - 25k", label: "00k - 25k" },
  { value: "25k-50k", label: "25k-50k" },
  { value: "50k-75k", label: "50k-75k" },
  { value: "75k-1L", label: "75k-1L" },
  { value: ">1L", label: ">1L" },
];
const fuel = [
  { label: "Petrol", value: "Petrol" },
  { label: "Diesel", value: "Diesel" },
  { label: "Electric", value: "Electric" },
  { label: "Hybrid", value: "Hybrid" },
];
const tyre = [
  { label: "25% used", value: "25% used" },
  { label: "50% used", value: "50% used" },
  { label: "75% used", value: "75% used" },
  { label: "100% used", value: "100% used" },
];
const auction = [
  { label: "No", value: "No" },
  { label: "Private", value: "Private" },
  { label: "Government", value: "Government" },
];
const accident = [
  { label: "Minor", value: "Minor" },
  { label: "Medium", value: "Medium" },
  { label: "Big", value: "Big" },
  { label: "None", value: "None" },
];
function Valuation() {
  const location = useLocation();
  const { plan, price } = location.state || {};
  // console.log(plan, price);
  const [carModel, setCarModel] = useState("");
  const [cc, setCC] = useState("");
  const [tyreCondition, setTyreCondition] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [registrationZone, setRegistrationZone] = useState([]);
  const [purchaseYear, setPurchaseYear] = useState("");
  const [kmDriven, setKmDriven] = useState("");
  const [minSellingPrice, setMinSellingPrice] = useState("");
  const [maxSellingPrice, setMaxSellingPrice] = useState("");
  const [carColor, setCarColor] = useState([]);
  const [colorId, setColorId] = useState("");
  const [brands, setBrands] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedRepair, setSelectedRepair] = useState(null);
  const [selectedFuel, setSelectedFuel] = useState(null);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [selectedAccident, setSelectedAccident] = useState(null);
  const [selectedTrans, setSelectedTrans] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [planRuppes, setPlanRuppes] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState(false);

  const [errors, setErrors] = useState({
    selectedValue: "",
    carModel: "",
    cc: "",
    colorId: "",
    selectedTrans: "",
    purchaseYear: "",
    selectedLocation: "",
    kmDriven: "",
    selectedAccident: "",
    selectedAuction: "",
    selectedFuel: "",
    selectedRepair: "",
    tyreCondition: "",
    minSellingPrice: "",
    maxSellingPrice: "",
    uploadedImages: "",
  });

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!selectedValue) {
      errors.selectedValue = "Brand is required";
      valid = false;
    }
    if (!carModel) {
      errors.carModel = "Model is required";
      valid = false;
    }
    if (!cc) {
      errors.cc = "CC is required";
      valid = false;
    }
    if (!colorId) {
      errors.colorId = "Color is required";
      valid = false;
    }
    if (!selectedTrans) {
      errors.selectedTrans = "Transmission Type is required";
      valid = false;
    }
    if (!purchaseYear) {
      errors.purchaseYear = "purchaseYear is required";
      valid = false;
    }
    if (!kmDriven) {
      errors.kmDriven = "kmDriven is required";
      valid = false;
    }
    if (!selectedLocation) {
      errors.selectedLocation = "Location is required";
      valid = false;
    }
    if (!selectedAccident) {
      errors.selectedAccident = "Accident Condition is required";
      valid = false;
    }
    if (!selectedAuction) {
      errors.selectedAuction = "Auction Details is required";
      valid = false;
    }
    if (!selectedFuel) {
      errors.selectedFuel = "Fuel Type is required";
      valid = false;
    }
    if (!tyreCondition) {
      errors.tyreCondition = "Tyre Condition is required";
      valid = false;
    }
    if (!selectedRepair) {
      errors.selectedRepair = "Repairing Cost is required";
      valid = false;
    }
    if (!minSellingPrice) {
      errors.minSellingPrice = "Minimum price is required";
      valid = false;
    }
    if (!maxSellingPrice) {
      errors.maxSellingPrice = "Maximum price is required";
      valid = false;
    }
    if (uploadedImages.length === 0) {
      errors.uploadedImages = "Please upload at least one image";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);

    if (files.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadedImages: "Please select at least one image.",
      }));
      return;
    }
    const imageUrls = files.map((file) => URL.createObjectURL(file));

    setUploadedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles, ...files];
      console.log("Updated uploadedFiles:", updatedFiles);
      return updatedFiles;
    });

    setUploadedImages((prevImages) => {
      const updatedImages = [...prevImages, ...imageUrls];
      console.log("Updated uploadedImages:", updatedImages);
      return updatedImages;
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      uploadedImages: "",
    }));
  };

  const handleRepairChange = (selectedOption) => {
    setSelectedRepair(selectedOption);
  };
  const handleFuel = (option) => {
    setSelectedFuel(option);
  };
  const handleTyre = (option) => {
    setTyreCondition(option.value);
  };
  const handleAuction = (value) => {
    setSelectedAuction(value);
  };
  const handleAccident = (option) => {
    setSelectedAccident(option);
    console.log("Selected accident:", option.value);
  };
  const handleTrans = (option) => {
    setSelectedTrans(option);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getBrands = async () => {
    try {
      const response = await Api.get(`brand`);
      const response1 = await Api.get(`valuation/get_registration_zone`);
      const response2 = await Api.get(`color`);
      const response3 = await Api.get(`valuation/get_valuation_plan`);
      console.log("plan : ", response3);
      setRegistrationZone(response1.data.location_data);
      setBrands(response.data.get_brand_details);
      setCarColor(response2.data.color);
      setPlanRuppes(response3.data.plan);
    } catch (error) {
      console.log("Error fetching brands:", error);
    }
  };
  useEffect(() => {
    getBrands();
  }, []);

  const styles = {
    container: {
      maxWidth: isMobile ? "100%" : "1200px",
      margin: "0 auto",
      padding: "20px",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      gap: "20px",
    },
    sliderWrapper: {
      flex: 1,
      marginBottom: "20px",
      marginTop: "25px",
    },
    productInfo: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "20px",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
    },
    productName: {
      fontSize: isMobile ? "1.5rem" : "2rem",
      marginBottom: "10px",
    },
    form: {
      width: "100%",
    },
    label: {
      fontSize: "1rem",
      marginBottom: "5px",
      display: "block",
      width: "100%",
    },
    input: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    input2: {
      width: "95%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    textarea: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    buyButton: {
      padding: "10px 20px",
      fontSize: "1rem",
      color: "#fff",
      backgroundColor: "#0073e6",
      border: "none",
      cursor: "pointer",
      alignSelf: "center",
      marginTop: "20px",
      width: isMobile ? "100%" : "auto",
    },
    uploadedImages: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      marginTop: "20px",
    },
    uploadedImage: {
      width: isMobile ? "80px" : "180px",
      height: isMobile ? "80px" : "100px",
      objectFit: "cover",
      borderRadius: "8px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    column2: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    rows: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    t2: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    optionButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "10px",
      border: "1px solid grey",
      borderRadius: "5px",
      cursor: "pointer",
    },
    selectedOption: {
      borderColor: "blue",
    },
    optionText: {
      marginLeft: "5px",
      fontSize: "16px",
    },
    confirmationModal: {
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "1000",
    },
    modalContent: {
      backgroundColor: "white",
      borderRadius: " 5px",
      textAlign: " center",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
      backgroundColor: "white",
      color: "black",
      padding: "34px 52px",
      margin: "10px",
      cursor: "pointer",
    },
    buttonBtn: {
      backgroundColor: "#0073e6",
      color: "white",
      border: "none",
      outline: "none",
      padding: "6px 19px",
    },
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxWidth: isMobile ? "100%" : "220px",
      padding: "4px",
      bottom: "5px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "95%",
    }),
  };
  const navigate = useNavigate();

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleConfirm = () => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const uid = localStorage.getItem("user_id");
    try {
      if (validate()) {
        const formData = new FormData();
        formData.append("user_id", uid);
        formData.append("company", selectedValue);
        formData.append("model", carModel);
        formData.append("car_cc", cc);
        formData.append("color_id", colorId);
        formData.append("trans_type", selectedTrans);
        formData.append("purchase_year", purchaseYear);
        formData.append("location_id", selectedLocation);
        formData.append("km_running", kmDriven);
        formData.append("estimated_cost_repair", selectedRepair.value);
        formData.append("fuel_type", selectedFuel.value);
        formData.append("auction", selectedAuction.value);
        formData.append("accident", selectedAccident.value);
        formData.append("tyre_condition", tyreCondition);
        formData.append("expected_price_minimum", minSellingPrice);
        formData.append("expected_price_maximum", maxSellingPrice);

        for (let i = 0; i < uploadedFiles.length; i++) {
          formData.append("car_multi_image[]", uploadedFiles[i]);
        }

        console.log("formdata>>>>>>>", formData);


        const response = await Api.postFormdata("valuation", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response:", response);
        navigate("/payall", {
          state: {
            url: response.data.payment_url,
          },
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <NavBar />
      <div style={styles.container}>
        <div style={styles.sliderWrapper}>
          <Carousel showThumbs={false} infiniteLoop autoPlay>
            <div>
              <img
                src="https://wallpapersmug.com/download/3840x2160/37da88/mazda-off-road-5k.jpg"
                alt="Product 1"
              />
            </div>
            <div>
              <img
                src="https://wallpapersmug.com/download/3840x2160/9fe0a2/bmw-vision-m-next-concept-cars-hybrid-sports-car.jpeg"
                alt="Product 2"
              />
            </div>
            <div>
              <img
                src="https://images.wallpaperscraft.com/image/single/porsche_911_carrera_rsr_porsche_sports_car_139640_3840x2160.jpg"
                alt="Product 3"
              />
            </div>
          </Carousel>
        </div>
        <div style={styles.productInfo}>
          <h1 style={styles.productName}>Value Your Car</h1>
          <form style={styles.form} onSubmit={handleSubmit}>
            <label style={styles.label}>Select Your Car Brand:</label>
            <select
              style={styles.input}
              onChange={(e) => setSelectedValue(e.target.value)}
            >
              <option value="">Select Car Brand</option>
              {brands.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.title}
                </option>
              ))}
            </select>
            {errors.selectedValue && (
              <span style={{ color: "red" }}>{errors.selectedValue}</span>
            )}

            <label style={styles.label}>Enter Car Model/Variant:</label>
            <input
              type="text"
              style={styles.input}
              value={carModel}
              onChange={(e) => setCarModel(e.target.value)}
            />
            {errors.carModel && (
              <span style={{ color: "red" }}>{errors.carModel}</span>
            )}
            <div style={styles.column}>
              <label style={styles.label}>Enter Engine Power(cc):</label>
              <input
                type="text"
                style={styles.input}
                value={cc}
                onChange={(e) => setCC(e.target.value)}
                error={errors.cc}
              />
              {errors.cc && <span style={{ color: "red" }}>{errors.cc}</span>}
            </div>
            <div style={styles.row}>
              <div>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Color :
                </label>
                <select
                  style={styles.input}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setColorId(e.target.value);
                  }}
                >
                  <option value="">Select color</option>
                  {carColor.map((name) => (
                    <option key={name.id} value={name.id}>
                      {name.color_name}
                    </option>
                  ))}
                </select>
                {errors.colorId && (
                  <span style={{ color: "red" }}>{errors.colorId}</span>
                )}
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={styles.label}>Model Year:</label>
                <input
                  type="text"
                  style={styles.input2}
                  value={purchaseYear}
                  onChange={(e) => setPurchaseYear(e.target.value)}
                  error={errors.purchaseYear}
                />
                {errors.purchaseYear && (
                  <span style={{ color: "red" }}>{errors.purchaseYear}</span>
                )}
              </div>
              <div style={styles.column}>
                <label style={styles.label}>KM Driven:</label>
                <input
                  type="text"
                  style={styles.input2}
                  value={kmDriven}
                  onChange={(e) => setKmDriven(e.target.value)}
                  error={errors.kmDriven}
                />
                {errors.kmDriven && (
                  <span style={{ color: "red" }}>{errors.kmDriven}</span>
                )}
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Estimate Repairing Cost:
                </label>
                <Select
                  value={selectedRepair}
                  onChange={handleRepairChange}
                  options={repairOptions}
                  styles={customStyles}
                  error={errors.selectedRepair}
                />
                {errors.selectedRepair && (
                  <span style={{ color: "red" }}>{errors.selectedRepair}</span>
                )}
              </div>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Registration Zone :
                </label>
                <select
                  style={styles.input}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSelectedLocation(e.target.value);
                  }}
                >
                  <option value="">Select Location</option>
                  {registrationZone.map((name) => (
                    <option key={name.id} value={name.id}>
                      {name.location_name}
                    </option>
                  ))}
                </select>
                {errors.selectedLocation && (
                  <span style={{ color: "red" }}>
                    {errors.selectedLocation}
                  </span>
                )}
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Fuel Type:
                </label>
                <Select
                  value={selectedFuel}
                  onChange={handleFuel}
                  options={fuel}
                  styles={customStyles}
                  error={errors.selectedFuel}
                />
                {errors.selectedFuel && (
                  <span style={{ color: "red" }}>{errors.selectedFuel}</span>
                )}
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Auction Details:
                </label>
                <Select
                  value={selectedAuction}
                  onChange={handleAuction}
                  options={auction}
                  styles={customStyles}
                  error={errors.selectedAuction}
                />
                {errors.selectedAuction && (
                  <span style={{ color: "red" }}>{errors.selectedAuction}</span>
                )}
              </div>

              <div style={styles.row}>
                <div style={styles.column}>
                  <label style={{ ...styles.label, marginBottom: "10px" }}>
                    Tyre Condition:
                  </label>
                  <Select
                    value={tyre.find((opt) => opt.value === tyreCondition)}
                    onChange={handleTyre}
                    options={tyre}
                    styles={customStyles}
                    error={errors.tyreCondition}
                  />
                  {errors.tyreCondition && (
                    <span style={{ color: "red" }}>{errors.tyreCondition}</span>
                  )}
                </div>
              </div>
            </div>

            <label style={styles.label}>
              Min Expected Price : (please don't use any special character)
            </label>
            <input
              type="number"
              style={styles.input}
              placeholder="please enter numeric value only"
              min="1"
              value={minSellingPrice}
              onChange={(e) => setMinSellingPrice(e.target.value)}
              error={errors.minSellingPrice}
            />
            {errors.minSellingPrice && (
              <span style={{ color: "red" }}>{errors.minSellingPrice}</span>
            )}

            <label style={styles.label}>
              Max Expected Price : (please don't use any special character)
            </label>
            <input
              type="number"
              style={styles.input}
              placeholder="please enter numeric value only"
              min="1"
              value={maxSellingPrice}
              onChange={(e) => setMaxSellingPrice(e.target.value)}
              error={errors.maxSellingPrice}
            />
            {errors.maxSellingPrice && (
              <span style={{ color: "red" }}>{errors.maxSellingPrice}</span>
            )}

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={styles.label}>Accidental Condition:</label>
                <Select
                  value={selectedAccident}
                  onChange={handleAccident}
                  options={accident}
                  styles={customStyles}
                  error={errors.selectedAccident}
                />
                {errors.selectedAccident && (
                  <span style={{ color: "red" }}>
                    {errors.selectedAccident}
                  </span>
                )}
              </div>
            </div>

            <div style={styles.rows}>
              <div style={styles.column}>
                <label style={styles.label}>Transmission type :</label>
                <div style={styles.rows}>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedTrans === "Manual" && styles.selectedOption),
                    }}
                    onClick={() => handleTrans("Manual")}
                    error={errors.selectedTrans}
                  >
                    {selectedTrans === "Manual" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Manual</span>
                  </div>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedTrans === "Automatic" &&
                        styles.selectedOption),
                    }}
                    onClick={() => handleTrans("Automatic")}
                  >
                    {selectedTrans === "Automatic" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Automatic</span>
                  </div>
                </div>
                {errors.selectedTrans && (
                  <span style={{ color: "red" }}>{errors.selectedTrans}</span>
                )}
              </div>
            </div>

            <label style={{ ...styles.label, marginTop: 10 }}>
              Upload Car Image (Multiple):
            </label>
            <input
              type="file"
              multiple
              onChange={handleImageUpload}
              style={styles.input}
              error={errors.uploadedFiles}
            />

            <div style={styles.uploadedImages}>
              {uploadedImages.map((imageUrl, index) => (
                <img
                  key={index}
                  src={imageUrl}
                  alt={`Uploaded ${index + 1}`}
                  style={styles.uploadedImage}
                />
              ))}
            </div>
            {errors.uploadedImages && (
              <span style={{ color: "red" }}>{errors.uploadedImages}</span>
            )}

            <div style={{ display: "flex" }}>
              <p style={{ display: "flex", gap: "10px" }}>
                Note<span>:</span>
              </p>
              <div style={{ marginLeft: "10px" }}>
                <p>1) The valuation will be sent to you in one hour.</p>
                <p>
                  2) Demand and market trends are the foundation of the stated
                  valuation. After a car is physically inspected, the price may
                  change.
                </p>
                <p>
                  3) If the offered valuation meets your needs, we are prepared
                  to make the purchase.
                </p>
                <p>
                  4) This valuation is valid for seven days following the
                  valuation.
                </p>
                <p>5) Terms and condition apply.</p>
              </div>
            </div>

            {loading ? (
              <div style={styles.buyButton}>Loading...</div>
            ) : (
              <button type="submit" style={styles.buyButton}>
                Save & Next{" "}
                <spanm
                  style={{
                    color: "#ff8f65",
                    fontWeight: "bold",
                  }}
                >
                  रु.{planRuppes}
                </spanm>
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Valuation;
