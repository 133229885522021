import React from 'react'
import { Link } from "react-router-dom";

const PlaystoreIcon = () => {
  return (
    <div>
        <Link to="https://play.google.com/store/apps/details?id=com.ctcnepal.siance&pcampaignid=web_share" target="_blank" rel="noopener noreferrer"><img style={{
          position: "fixed",
          right: "20px",
          bottom: "70px",
          zIndex: "900",
        }} src="img/playstore.png" width="100" alt='...' /></Link>
      </div>
  )
}

export default PlaystoreIcon
