import React, { useState, useEffect } from 'react'
import Api from "../Utills/Api";
import { useNavigate } from "react-router-dom";

const Recommended = () => {

    const [products, setProducts] = useState([]);
    const userId = localStorage.getItem('user_id');
    const navigate=useNavigate();

    const getProducts = async () => {
        try {
            const response = await Api.get('home/home_recommended');
            console.log("recomented : ",response);
            setProducts(response.data.get_premium_car_details);
        } catch (error) {
            console.log("Error fetching products:", error);
        }
    };

    const ownerButton = async (event, user_id, sale_id) => {
      event.preventDefault();
      if (userId) {
        try {
          await Api.post(`car/add_wishlist`, {
            user_id: userId,
            sale_id: sale_id,
          });
          navigate('/seller', { state: { user_id: user_id } });
        } catch (error) {
          console.log("Error adding to wishlist:", error);
        }
      } else {
        navigate('/login');
      }
    };

    useEffect(() => {
        getProducts();
    }, []);

    return (
        <div className="container-xxl py-5">
            <div className="container">
                <h1 style={{ marginBottom: "20px" }}>Recommended Car</h1>
                <div className="row g-4">
                    {products.map((member) => (
                        <div
                            className="col-lg-3 col-md-6 wow fadeInUp"
                            data-wow-delay={member.delay}
                            key={member.id}
                            style={{
                                height: "300px"
                              }}
                        >
                            <div className="team-item  team-item1"
                                style={{
                                    boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                                    borderRadius: "10px",
                                    height: "100%"
                                }}>
                                <div
                                    className="all-car position-relative"
                                    style={{ height: "60%" }}
                                >
                                    <img className="buy-car-image"
                                        src={`https://admin.ctcnepal.com/uploads/car_banner/${member.car_banner}`}
                                        alt=""
                                        style={{
                                            width: "100%",
                                            objectFit: "cover",
                                            height: "100%",
                                            borderTopLeftRadius: "10px",
                                            borderTopRightRadius: "10px",
                                            transform: "transition 0.5s"
                                        }}
                                    />
                                    <div className="rotateImage team-overlay">
                                        <a
                                            className="btn btn-square mx-1"
                                            href=""
                                            style={{
                                                width: "70px",
                                                height: "70px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                transform: "transition 0.5s",
                                                borderRadius: "7px"
                                            }}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                navigate(`/productDetails?sale_id=${member.id}`, { state: { sale_id: member.id } });
                                            }}
                                        >
                                            <p style={{ margin: 0 }}>Buy Now</p>
                                        </a>
                                        <a
                                            className="btn btn-square mx-1"
                                            href=""
                                            style={{
                                                width: "70px",
                                                height: "70px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "7px"
                                            }}
                                            onClick={(event) => ownerButton(event, member.user_id, member.id)}
                                        >
                                            <p style={{ margin: 0 }}>Seller Details</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="bg-light text-center" style={{
                                    padding: "13px",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                }}>
                                    <h5 className="fw-bold" style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '90%',
                                        margin: '0 auto',
                                    }}>
                                        {member.title} {member.model}
                                    </h5>
                                    <h5 className="fw-normal">रु.{member.sale_price}</h5>
                                    <small>
                                        {member.km_running}km • {member.color} • {member.fuel_type}
                                    </small>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Recommended;
