import React from 'react'
import { Link } from "react-router-dom";

const AppStoreIcon = () => {
  return (
    <div>
        <Link to="https://apps.apple.com/in/app/car-trade-centre/id6736762328" target="_blank" rel="noopener noreferrer"><img style={{
          position: "fixed",
          right: "20px",
          bottom: "30px",
          zIndex: "900",
        }} src="img/appstore.png" width="100" alt='...' /></Link>
      </div>
  )
}

export default AppStoreIcon
